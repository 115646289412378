$(function () {
    setHeaderScroll();
    hamburger();

});


//Lightcase
$(document).ready(function() {
	$('a[data-rel^=lightcase]').lightcase();

  	//Sub-menu
	var button_text = "Zobrazit navigaci";
	$('#page-detail__menu-mobile-trigger').on('click', function (e) {
		e.preventDefault();
		$(this).text(
			button_text == "Skrýt navigaci" ? "Zobrazit navigaci" : "Skrýt navigaci"
		);
		$('#page-detail__submenu').slideToggle(200);
		button_text = $(this).text();
	});

  $(document).ready(function() {
    $('.banner-hp').slick({
      dots: false,
      speed: 300,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      prevArrow: $('.slick__arrow.arrow__prev.banner__arrow'),
      nextArrow: $('.slick__arrow.arrow__next.banner__arrow')
    });
  });
});
